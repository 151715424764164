export const TAGS = Object.freeze({
  disable_status_talent_profile: 'disable_status_talent_profile',
  public_talent_detail: 'public-talent-detail',
  send_Talent_Feedback: 'send-talent-feedback',
  validate_talent_information: 'validate-talent-information',
  list_of_talents_from_recruiter: 'list-of_talents-from-recruiter',
  all_recruiters_from_admin: 'all-recruiters-from-admin',
  recruiter_detail: 'recruiter-detail',
  complete_or_incomplete_talents: 'complete-or-incomplete-talents',
  my_list_of_talents: 'my-list-of-talents',
  activate_offer_management: 'activate-offer-management',
  new_notification_list: 'new-notification-list',
  hide_new_notification_list: 'hide-new-notification-list',
  list_of_recruiter_network: 'list-of-recruiter-network',
  list_of_offers_management: 'list-of-offers-management',
  all_notifications: 'all-notifications',
  analytics_master: 'Analytics-master',
  companies: 'companies',
  active_recruiter_or_company: 'active-recruiter-or-company',
  profile_user: 'profile-user',
  forget_password: 'forget-password',
  recovery_password: 'recovery-password',
  decode: 'decode',
  company_detail: 'company-detail',
  send_feedback: 'send-feedback',
  change_password: 'change-password',
  change_email: 'change-email',
  decode_token: 'decode-token',
  linkedin_login: 'linkedin-login',
  change_manager: 'change-manager',
  send_offer_talent: 'send-offer-talent',
  user_resumen_data: 'user-resumen-data',
  emails: 'emails',
  categories: 'categories',
  list_of_company_sectors: 'list-of-company-sectors',
  profile_data: 'profile-data',
  create_fast_offer_management: 'create-fast-offer-management',
  create_city: 'create-city',
  create_language: 'create-language',
  list_of_idioms: 'list-of-idioms',
  list_of_fast_offers: 'list-of-fast-offers',
  fast_offer_detail: 'fast-offer-detail',
  update_works_experience: 'update-works-experience',
  save_talent_info: 'save-talent-info',
  icon_mutate: 'icon-mutate',
  my_offers_talent: 'my-offers-talent',
  offer_management_detail: 'offer-management-detail',
  accept_job_offer: 'accept-job-offer',
  reason_for_decline_job_offer: 'reason-for-decline-job-offer',
  decline_job_offer: 'decline-job-offer',
  offers_company: 'offers-company',
  offer_management_company_detail: 'offer-management-company-detail',
  list_of_coins: 'list-of-coins',
  states_offer: 'states-offer',
  match_talents: 'match-talents',
  favorites_candidates_job_offer: 'favorites-candidates-job-offer',
  add_favorite: 'add-favorite',
  list_of_candidates: 'list-of-candidates',
  activate_or_desactivate_job_offer: 'activate-or-desactivate-job-offer',
  my_offer_talent_detail: 'my-offer-talent-detail',
  send_offers: 'send-offers',
  remove_favorite: 'remove-favorite',
  allow_access_company: 'allow-access-company',
  allow_access_job_offer: 'allow-access-job-offer',
  list_of_validate_fast_offers: 'list-of-validate-fast-offers',
  send_validate_offer: 'send-validate-offer',
  validate_offer_detail: 'validate-offer-detail',
  desactive_job_offer: 'desactive-job-offer',
  send_contact_information: 'send-contact-information',
  talents_information_data: 'talents-information-data',
  create_state: 'create-state',
  list_of_hiring_advisors: 'list-of-hiring-advisors',
  create_hiring_advisor: 'create-hiring-advisor',
  assign_company: 'assign-company',
  list_of_companies: 'list-of-companies',
  list_of_offers: 'list_of_offers',
  state_send_offers: 'state-send-offers',
  state_send_offers_count: 'state-send-offers-count',
  columns_process: 'columns-process',
  candidates_process: 'candidates-process',
  add_column: 'add-column',
  change_candidate_column: 'change-candidate-column',
  create_note: 'create-note',
  change_column: 'change-column',
  delete_column: 'delete-column',
  candidate_note: 'candidate-note',
  update_note: 'update-note',
  list_of_skills: 'list-of-skills',
  all_offers_from_recruiter: 'all-offers-from-recruiter',
  create_chat: 'create-chat',
  list_of_chats: 'list-of-chats',
  chat_detail: 'chat_detail',
  message_history: 'message_history',
  desactive_user: 'desactive-user',
  list_of_recruiters: 'list-of-recruiters',
  create_fast_job_offer_by_ha: 'create_fast_job_offer_by_ha',
  change_all_talents: 'change_all_talents',
  verify_password: 'verify-password',
  update_status_profile_talent: 'update-status-profile-talent',
  change_username: 'change-username',
  profile_hiring_advisor: 'profile-hiring-advisor',
  list_offers_order_ha: 'list-offers-order-ha',
  open_ticket: 'open-ticket',
  send_message_ticket: 'send-message-ticket',
  list_of_messages_tickets: 'list-of-messages-tickets',
  list_of_tickets: 'list-of-tickets',
  close_ticket: 'close-ticket',
  second_validation: 'second-validation',
  rate_support_experience: 'rate-support-experience',
  create_quick_offer: 'create_quick_offer',
  list_of_quick_offers: 'list-of-quick-offers',
  change_quick_offer_status: 'change-quick-offer-status',
  list_of_ranking_top_three_recruiters: 'list-of-ranking-top_three-recruiters',
  list_of_ranking_recruiters: 'list-of-ranking-recruiters',
  pdf_linkedin: 'pdf-linkedin',
  talents_registered: 'talents-registered',
  count_talents_active: 'count-talents-active',
  list_of_soft_skills: 'list-of-soft-skills',
  talents_managers_registered: 'talents-managers-registered',
  talents_validates: 'talents-validates',
  talents_with_complete_profile: 'talents-with-complete-profile',
  graph_count_connections: 'graph_count_connections',
  count_talents_activated: 'count_talents_activated',
  offer_detail_by_recruiter: 'offer-detail-by-recruiter',
  list_of_offers_from_my_network: 'list-of-offers-from-my-network',
  metrict_talents: 'metrict-talents',
  metrict_recruiters: 'metrict-recruiters',
  graph_count_registered_talents: 'graph-count-registered-talents',
  graph_count_validated_talents: 'graph-count-validated-talents',
  graph_count_registered_recruiters: 'graph-count-registered-recruiters',
  graph_count_actives_recruiters: 'graph-count-actives-recruiters',
  list_of_contries: 'list-of-contries',
  list_of_states: 'list-of-states',
  list_of_cities: 'list-of-cities',
  counter_talents: 'counter-talents',
  activate_talent: 'activate-talent',
  skills_categories: 'skills-categories',
  search_talents: 'search-talents',
  list_active_processes: 'list-active-processes',
  active_processes_position_talent: 'active-processes-position-talent',
  tm_last_connection: 'tm-last-connection',
  update_send_feedback_modal_mutate: 'update-send-feedback-modal-mutate',
  companies_counts: 'companies-counts',
  tm_list_companies: 'tm-list-companies',
  tm_list_companies_aproved: 'tm-list-companies-aproved',
  set_company_data: 'set-company-data',
  list_of_posts: 'list-of-posts',
  visible_offer: 'visible-offer',
  update_interview_status: 'update-interview-status',
  accountants_quick_companies: 'accountants-quick-companies',
  list_of_active_companies: 'list-of-active-companies',
  list_of_order_companies: 'list-of-order-companies',
  change_state_active_company: 'change-state-active-company',
  remove_quick_company: 'remove-quick-company',
  change_recruiter: 'change-recruiter',
  activate_talent_tm_from_admin: 'activate-talent-tm-from-admin',
  update_talent_info: 'update-talent-info',
  update_studies: 'update-studies',
  update_certifications: 'update-certifications',
  get_soft_skills: 'get-soft-skills',
  edit_soft_skills: 'edit_soft_skills',
  create_soft_skills: 'create-soft-skills',
  single_quick_company: 'single-quick-company',
  create_note_quick_companies: 'create-note-quick-companies',
  register_google: 'register-google',
  get_settings: 'get-settings',
  change_notification_state: 'change-notification-state',
  list_of_accountants_companies: 'list-of-accountants-companies',
})
