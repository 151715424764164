import { BASE_URL } from '../constants'

const token = JSON.parse(localStorage.getItem('token'))

export const getEncodeData = async (data) => {
  const rawBody = JSON.stringify({
    token: data,
  })

  const URL = `${BASE_URL}/decode`

  try {
    const res = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: rawBody,
    })

    const getData = await res.json()

    if (res?.status === 200) {
      localStorage.setItem('user-info', JSON.stringify(getData))
    } else {
      localStorage.removeItem('user-info')
    }

    return getData
  } catch (error) {
    console.log(error)
  }
}

export const logOut = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('user-info')
}

export const forgetPassword = async (data) => {
  const rawBody = JSON.stringify({
    email: data.email,
  })

  const URL = `${BASE_URL}servicesForUsers/sedingEmailForPasswordRecovery`

  try {
    const res = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: rawBody,
    })
    const getData = await res.json()

    return { getData, res }
  } catch (error) {
    console.log(error)
  }
}

export const recoveryPassword = async (data) => {
  const rawBody = JSON.stringify({
    password: data.password,
  })

  const URL = `${BASE_URL}servicesForUsers/passwordRecovery`

  try {
    const res = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token_auth: data.code ?? '',
      },
      body: rawBody,
    })
    const getData = await res.json()

    return { res, getData }
  } catch (error) {
    console.log(error)
  }
}

export const changePassword = async (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}changepassworduser`

  try {
    const res = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })
    const getData = await res.json()

    return { res, getData }
  } catch (error) {
    console.log(error)
  }
}

export const sendCodeChangeEmail = async (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}validatecangeemail`

  try {
    const res = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })
    const getData = await res.json()

    return { res, getData }
  } catch (error) {
    console.log(error)
  }
}

export const changeEmail = async (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}changeenamil`

  try {
    const res = await fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })
    const getData = await res.json()

    return { res, getData }
  } catch (error) {
    console.log(error)
  }
}

export const verifyPassword = async (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}user/validationPass`

  try {
    const res = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })
    const getData = await res.json()

    return { res, getData }
  } catch (error) {
    console.log(error)
  }
}

export const getSettings = () => {
  const URL = `${BASE_URL}/user/settings/get`

  try {
    const response = fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
    })
      .then((response) => response.json())
      .then((res) => res?.settings)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const changeNotificationState = async (data) => {
  const rawBody = JSON.stringify(data)

  const URL = `${BASE_URL}user/settings/setNotifications`

  try {
    const res = await fetch(URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token?.token,
      },
      body: rawBody,
    })
    const getData = await res.json()

    return { res, getData }
  } catch (error) {
    console.log(error)
  }
}


