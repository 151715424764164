import dayjs from 'dayjs'
import i18next from 'i18next'
import { typeUser } from '../localStorage'
import Circle from '../components/Circle'
import PartyIcon from '../icons/PartyIcon'
import BriefcaseOutlineIcon from '../icons/BriefcaseOutlineIcon'

import very_bad_face_image from '../assets/very_bad_face.png'
import bad_face_image from '../assets/bad_face.png'
import regular_face_image from '../assets/regular_face.png'
import good_face_image from '../assets/good_face.png'
import excellent_face_image from '../assets/excellent_face.png'
import quick_offer_check from '../assets/quick_offer_check.png'
import quick_offer_process from '../assets/quick_offer_process.png'
import quick_offer_cancelled from '../assets/quick_offer_cancelled.png'
import tm_company from '../assets/tm-company.jpg'

export const SEE_MORE_TALENTS = 'Ver más talentos'
export const FIND_TALENTS = 'Buscar talentos'
export const PROFILE_OR_SKILLS = 'Perfil o Habilidad'
export const TEXT_HOME =
  'Somos expertos en perfiles de tecnología, conectamos empresas con talentos'
export const ABOUT_HUTRIT = '¿Sobre nosotros?'
export const HUTRIT_TEXT =
  'En hutrit logramos hacer que las empresas y talentos logren hacer match de una forma nunca antes vista, teniendo como prioridad lograr esto en el menor tiemo posible.'
export const MORE_INFORMATION = 'Más información'
export const LOKING_FOR_JOB = '¿Buscas un talento?'
export const ARE_YOU_RECRUITING = '¿Eres un reclutador?'
export const RECRUITING_TEXT =
  'Sabemos que encontrar al candidato perfecto puede ser todo un reto. Hutrit esta aquí para ayudarte a destacar entre la multitud y encontrar ese talento excepcional que estás buscando.'

export const CREATE_ACCOUNT = 'Crea tu cuenta'
export const CREATE_CV = 'Crea tu perfil'
export const RECEIVE_JOB_OFFERS = 'Recibe ofertas de empleo'
export const NAVBAR_ITEMS = {
  home: 'Inicio',
  about: 'Nosotros',
  contactUs: 'Contáctanos',
  talents: 'Talentos',
  workWithUs: 'Trabaja con nosotros',
}

export const LINKEDIN_URL = 'www.linkedin.com/in/'

export const PASSWORD_MIN_LENGTH_MESSAGE = 'Ingresar mínimo 8 caracteres'
export const ONLY_LETTERS_MESSAGE =
  'No se admiten números ni caracteres especiales'
export const VALIDATE_EMAIL_MESSAGE = 'Ingresar correo electrónico válido'
export const PASSWORD_MATCH_MESSAGE = 'Las contraseñas deben coincidir'
export const TYPE_OF_REGISTER = {
  talent: 1,
  recruiter: 2,
  company: 3,
}

export const TYPE_OF_ERRORS = {
  required: 'required',
  pattern: 'pattern',
  minLength: 'minLength',
}

export const PASSWORD_MIN_LENGTH = 8
export const USERNAME_MAX_LENGTH = 15
export const USERNAME_MAX_ELIPSIS_RENDER = 26

export const USERNAME_MAX_LENGTH_LG_VIEW = 15
export const USERNAME_MAX_LENGTH_SM_VIEW = 10

export const LASTEST_TALENTS = 'Últimos talentos'
export const VIEW_MORE_TALENTS = 'Ver más talentos'
export const SEE_TALENT = 'Ver talento'
export const TYPE_OF_STATUS = {
  on: 'Disponible',
  off: 'No disponible',
}

export const HUTRIT_PRIMARY_COLOR = '#00cf83'
export const HUTRIT_SECONDARY_COLOR = '#fff'

export const TYPE_OF_SALARY = [
  {
    id: 1,
    value: 'monthly',
    name: 'Mensual',
  },
  {
    id: 2,
    value: 'Anual',
    name: 'Anual',
  },
]

export const TYPE_OF_USER = {
  out_off_the_app: 0,
  talent: 1,
  recruiter: 2,
  company: 3,
  hiring_advisor: 4,
  admin: 57,
}

export const INVITATION_CODE_ORIGIN = `${window.location.origin}`

export const NOT_AVAILABLE = 'No disponible'
export const ITEMS_PER_PAGE = 10

export const YEARS_OF_EXPERIENCE = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '+10',
]

export const LANDING_SKILLS = [
  'Arquitectos TI',
  'Big Data',
  'Blockchain devps',
  'Business Intelligence',
  'Cloud Architect',
  'Cloud Developer',
  'Cloud Engineer',
  'Consultant',
  'Cybersecurity',
  'Data Analyst',
  'Data Engineer',
  'Data Scientist',
  'DBA',
  'Developer',
  'Ética Hacking',
  'Fullstack',
  'Infraestructura TI',
  'IT Manager PO',
  'Mobile Developer',
  'SER/DevOps',
  'Software Architect',
  'UX/UI',
  'Arquitectos TI',
  'Big Data',
  'Blockchain devps',
  'Business Intelligence',
  'Cloud Architect',
  'Cloud Developer',
  'Cloud Engineer',
  'Consultant',
  'Cybersecurity',
  'Data Analyst',
  'Data Engineer',
  'Data Scientist',
  'DBA',
  'Developer',
  'Ética Hacking',
  'Fullstack',
  'Infraestructura TI',
  'IT Manager PO',
  'Mobile Developer',
  'SER/DevOps',
  'Software Architect',
  'UX/UI',
  'Arquitectos TI',
  'Big Data',
  'Blockchain devps',
  'Business Intelligence',
  'Cloud Architect',
  'Cloud Developer',
  'Cloud Engineer',
  'Consultant',
  'Cybersecurity',
  'Data Analyst',
  'Data Engineer',
  'Data Scientist',
  'DBA',
  'Developer',
  'Ética Hacking',
  'Fullstack',
  'Infraestructura TI',
  'IT Manager PO',
  'Mobile Developer',
  'SER/DevOps',
  'Software Architect',
  'UX/UI',
]

export const VIEWPORT_LG = 992
export const VIEWPORT_XL = 1400

export const LINKEDIN_HUTRIT_URL =
  'https://www.linkedin.com/company/hutrit/mycompany/'

export const EMAIL_URL = 'mailto:help@hutrit.com'

export const DICTIONARY = {
  home: 'Inicio',
  how_does_it_work: 'Cómo funciona',
  start_now: 'Comenazar ahora',
  personal_profile: 'Perfil personal',
  location: 'Ubicación',
  additional_data: 'Datos adicionales',
  professional_profile: 'Perfil profesional',
  profession: 'Profesión',
  skills: 'Habilidades',
  skill: 'Habilidad',
  work_experience: 'Experiencia laboral',
  staff: 'Personal',
  lets_complete_your_profile: 'Completemos tu perfil',
  you_are_just_a_few_steps_away_from_completing_your_profile_and_attracting_the_best_opportunities:
    'Estás a solo unos pasos de completar tu perfil y atraer las mejores oportunidades.',
  tell_us_where_you_are_from: 'Cuéntanos de dónde eres',
  country: 'País',
  state_or_region: 'Estado o Región',
  city: 'Ciudad',
  next: 'Siguiente',
  these_data_will_not_be_shared_with_companies_your_profile_will_remain_anonymous:
    'Estos datos no serán compartidos con las empresas. Tu perfil se mantendrá anónimo.',
  write_your_contact_information: 'Escribe tus datos de contacto',
  phone_number: 'Número de teléfono',
  identification_document: 'Documento de identidad',
  linkedIn_profile: 'Perfil de LinkedIn',
  tell_us_about_yourself: 'Cuéntanos sobre ti',
  years_of_experience: 'Años de experiencia',
  we_will_use_this_information_to_find_the_ideal_job_for_you:
    'Utilizaremos esta información para conseguir el trabajo ideal para ti',
  highlight_your_skills: 'Destaca tus habilidades',
  focus_on_relevant_skills_highlight_those_related_to_the_position_you_hope_to_get:
    'Enfócate en habilidades relevantes, destaca aquellas relacionadas al puesto que esperas conseguir.',
  tip: 'Tip',
  english_level: 'Nivel de Inglés',
  english: 'Inglés',
  beginner: 'Principiante',
  intermediate: 'Intermedio',
  advanced: 'Avanzado',
  professional: 'Profesional',
  required: 'Requerido',
  level: 'Nivel',
  competence: 'Competencia',
  this_field_is_required: 'Este campo es requerido',
  tell_us_about_your_experience: 'Cuéntanos sobre tu experiencia',
  highlight_your_most_relevant_achievements_See_an_example_of_a:
    'Destaca tus logros más relevantes. Observa un ejemplo de un',
  successful_profile: 'perfil exitoso',
  post: 'Cargo',
  remove_experience: 'Remover experiencia',
  sector: 'Sector',
  company: 'Empresa',
  return: 'Volver',
  finish: 'Finalizar',
  finish_date: 'Fecha de finalización',
  start_date: 'Fecha de inicio',
  choose_the_position: 'Escoge el cargo',
  enter_the_company: 'Ingresa la empresa',
  choose_the_sector: 'Escoge el sector',
  i_work_here_currently: 'Trabajo aquí actualmente',
  activities_carried_out_and_achievements_obtained:
    'Actividades realizadas y logros obtenidos',
  you_have_completed_your_profile: '¡Has completado tu perfil!',
  you_are_one_step_closer_to_starting_your_next_project:
    'Estás un paso más cerca de comenzar en tu próximo proyecto',
  back_to_top: 'Volver al inicio',
  view_your_profile: 'Ver tu perfil',
  see_less: 'Ver menos',
  see_more: 'Ver más',
  show_all_experiences: 'Mostrar todas las Experiencias',
  review_now: 'Revisar ahora',
  remote: 'Remoto',
  requirements: 'Requisitos',
  required_skills: 'Habilidades requeridas',
  desired_profile_and_additional_benefits:
    'Perfil deseado y beneficios adicionales',
  description_of_the_position_and_functions_to_be_performed:
    'Descripción del cargo y funciones a realizar',
  download: 'Descargar',
  preview_what_the_offer_will_look_like: 'Previsualiza cómo se verá la oferta',
  Share: 'Compartir',
  we_seek: 'Buscamos',
  write_me_or_leave_me_a_comment_for_more_information:
    'Escríbeme o déjame un comentario para más información',
  share_this_offer: 'Comparte esta oferta',
  these_data_will_not_be_shared_with_companies:
    'Estos datos no serán compartidos con las empresas',
  your_profile_will_remain_anonymous: 'Tu perfil se mantendrá anónimo.',
  personal_information_updated_correctly:
    'Información personal actualizada correctamente',
  error_in_the_system: 'Error en el sistema',
  save: 'Guardar',
  visualize_and_highlight_your_professional_profile_as_companies_see_it:
    'Visualiza y destaca tu perfil profesional tal como lo ven las empresas',
  available: 'Disponible',
  not_available: 'No disponible',
  of: 'de',
  experience: 'Experiencia',
  favorite: 'Favorito',
  go_back: 'Regresar',
  sign_up_in_seconds: 'Regístrate en segundos',
  general_skills: 'Habilidades Generales',
  show_all_skills: 'Mostrar todas las Habilidades',
  professional_header: 'Encabezado profesional',
  professional_information_updated_correctly:
    'Información Profesional actualizada correctamente',
  successfully_updated_work_experience:
    'Experiencia laboral actualizada correctamente',
  active_offers: 'Ofertas activas',
  offers_sent: 'Ofertas enviadas',
  offers_accepted: 'Ofertas aceptadas',
  attracts_the_attention_of_companies: 'Atrae la atención de las empresas',
  find_the_talent_you_need: 'Encuentra el talento que necesitas',
  your_profile_is_your_showcase_to_new_opportunities:
    'Tu perfil es tu vitrina hacia nuevas oportunidades.',
  by_completing_it_you_allow_ompanies_to_discover_you:
    'Al completarlo, permites que las empresas te descubran.',
  open_the_doors_to_a_universe_of_qualified_professionals:
    'Abre las puertas a un universo de profesionales calificados.',
  complete_your_profile_create_your_job_offer_and_let_our_platform_take_care_of_the_rest:
    'Completa tu perfil, crea tu oferta de empleo y deja que nuestra plataforma se encargue del resto.',
  complete_my_profile: 'Completar mi perfil',
  offers: 'Ofertas',
  talents: 'Talentos',
  my_profile: 'Mi perfil',
  company_profile: 'Perfil de empresa',
  user_profile: 'Perfil de usuario',
  statistics: 'Estadísticas',
  your_summary: 'Tu resumen',
  your_progress: 'Tu progreso',
  back: 'Atrás',
  lets_start: 'Comencemos',
  lets_find_the_talent_you_are_looking_for: 'Encontremos el talento que buscas',
  we_help_you_find_the_talent_you_need_in_a_few_steps:
    'Te ayudamos a buscar el talento que necesitas en pocos pasos',
  about_the_company: 'Sobre la empresa',
  company_data: 'Datos de la empresa',
  contact_information: 'Datos de contacto',
  business_values: 'Valores de la empresa',
  tell_us_about_the_company: 'Cuéntanos sobre la empresa',
  company_name: 'Nombre de la empresa',
  the_business_social_reason: 'Razón social de la empresa',
  tax_registry: 'Registro Fiscal',
  company_sector: 'Sector de la empresa',
  team_size: 'Tamaño del equipo',
  years_of_the_company: 'Años de la empresa',
  we_will_use_this_data_to_make_a_precise_match_with_the_ideal_talent_for_your_company:
    'Estos datos los utilizaremos para hacer un match preciso con el talento ideal para tu empresa.',
  company_logo: 'Logo de la empresa',
  or_drag_the_company_logo: 'o arrastra el logo de la empresa',
  goes_up: 'Sube',
  accepted_formats: 'Formatos aceptados',
  drop_here: 'soltar aqui',
  tell_us_where_they_are: 'Cuéntanos dónde están',
  later_you_will_be_able_to_find_talent_in_person:
    'Más adelante podrás encontrar talento de forma presencial',
  tell_us_about_your_values: 'Cuéntanos sobre sus valores',
  attract_the_right_talent_to_your_company_with_values_aligned_with_theirs:
    'Atrae el talento indicado a tu empresa con valores alineados a los suyos',
  mission: 'Misión',
  optional: 'opcional',
  vision: 'Visión',
  brief_description: 'Breve descripción',
  aspects_you_want_to_highlight: 'Aspectos que desean destacar',
  now_create_your_first_offer_and_discover_the_talent_that_will_transform_your_company:
    'Ahora, crea tu primera oferta y descubre al talento que transformará tu empresa.',
  create_offer: 'Crear oferta',
  your_new_offers_are_coming: 'Tus nuevas ofertas están por llegar',
  professional_experience: 'Experiencia Profesional',
  my_offers: 'Mis ofertas',
  all: 'Todas',
  accepted: 'Aceptadas',
  without_answer: 'Sin contestar',
  rejected: 'Rechazadas',
  rejectedTwo: 'Rechazada',
  decline: 'Rechazar',
  accept_now: 'Aceptar ahora',
  review_offer: 'Revisar oferta',
  financial_services: 'Servicios financieros',
  description: 'Descripción',
  workers: 'trabajadores',
  highlights: 'Aspectos destacados',
  in_progress: 'En progreso',
  new: 'Nuevo',
  earring: 'Pendiente',
  create_first_offer: 'Crear primera oferta',
  you_have_not_created_offers_yet: 'No has creado ofertas aún',
  post_your_first_job_offer_and_let_our_ai_powered_technology_find_the_perfect_professional_for_you:
    'Publica tu primera oferta y deja que nuestra tecnología potenciada por IA encuentre el profesional perfecto para ti.',
  see_candidates: 'Ver candidatos',
  save_as_draft: 'Guardar como borrador',
  publish_offer: 'Publicar oferta',
  we_are_creating_your_offer: 'Estamos creando tu oferta',
  we_connect_the_talent_that_your_company_needs_to_measure:
    'Conectamos el talento que tu empresa necesita a la medida',
  your_offer_was_created_successfully: 'Tu oferta fue creada con éxito',
  go_to_favorites: 'Ir a Favoritos',
  back_to_offers: 'Volver a Ofertas',
  create_new_offer: 'Crear nueva oferta',
  type_of_salary_offered: 'Tipo de salario ofrecido',
  offer_name: 'Nombre de la oferta',
  assign_a_name_to_the_offer: 'Asigna un nombre a la oferta',
  position_offered: 'Cargo ofertado',
  salary_offered: 'Salario ofrecido',
  candidates: 'Candidatos',
  publication_date: 'Fecha de publicación',
  state: 'Estado',
  actions: 'Acciones',
  duplicate_offer: 'Duplicar oferta',
  bid_options: 'Opciones de la oferta',
  deactivate_offer: 'Desactivar oferta',
  legal_warning: 'Aviso legal',
  all_rights_reserved: 'Todos los derechos reservados',
  log_in: 'Iniciar sesión',
  sign_up: 'Regístrate',
  contact_us: 'Contáctanos',
  follow_us_on: 'Síguenos en:',
  privacy: 'Privacidad',
  terms_and_conditions: 'Términos y condiciones',
  category: 'Categoría',
  look_for: 'Buscar',
  sent: 'Enviados',
  profiles_matched: 'Perfiles que hicieron Match',
  review_profile: 'Revisar perfil',
  send_offer: 'Enviar oferta',
  you_are_about_to_send_the_following_offer:
    'Estás por enviar la siguiente oferta',
  selected_candidates: 'Candidatos seleccionados',
  cancel: 'Cancelar',
  send_offers: 'Enviar ofertas',
  save_as_favorite: 'Guardar como favorito',
  search_offer: 'Buscar oferta',
  double_the_offer: 'Duplicar la oferta',
  offer_data: 'Datos de la oferta',
  search_filters: 'Filtros de búsqueda',
  categories: 'Categorías',
  search_categories: 'Buscar categorías',
  languages: 'idiomas',
  apply_filters: 'Aplicar filtros',
  candidates_associated_with_the_offer: 'Candidatos asociados a la oferta',
  keep_all: 'Conservar todos',
  you_are_about_to_deactivate_the_offer: 'Estás por desactivar la oferta',
  disabled: 'Desactivada',
  keep: 'Conservar',
  rule_out: 'Descartar',
  do_not_you_find_what_you_are_looking_for: '¿No encuentras lo que buscas?',
  let_our_team_find_your_perfect_candidate:
    'Deja que nuestro equipo encuentre a tu candidato perfecto.',
  create_your_offer: 'Crea tu oferta',
  allow_access: 'Permitir acceso',
  receive_access: 'Recibir acceso',
  find_your_talent: 'Encuentra tu talento',
  see_all_talents: 'Ver todos los talentos',
  sign_up_two: 'Registrate',
  become_a_pro_for_free: 'Conviértete en Pro gratis',
  go_pro_for_free_now: '¡Hazte Pro gratis ahora!',
  offers_created: 'Ofertas creadas',
  unvalidated_offers: 'Ofertas sin validar',
  offers_validated: 'Ofertas validadas',
  annual_gross: 'Bruto Anual',
  schedule_appointment: 'Agendar cita',
  our_team_is_here_to_help_you: 'Nuestro equipo está aquí para ayudarte. ',
  offer_detail: 'Detalle de oferta',
  administrator: 'Administrador',
  welcome: 'Bienvenido/a',
  process: 'Procesos',
  take_your_selection_process_seven_times_faster:
    'Lleva tu proceso de selección 7 veces más rápido',
  learn_how_to_use_hutrit_to_hire_quickly_in_just_a_few_steps:
    'Aprende cómo utilizar Hutrit para contratar rápidamente en pocos pasos',
  my_companies: 'Mis compañias',
  total_offers: 'Ofertas totales',
  hired: 'Contratados',
  status_update: 'Actualización de estado',
  messenger_service: 'Mensajeria',
  see_tutorial: 'Ver tutorial',
  chatgpt_assistant: 'Asistente ChatGPT',
  try_now: 'Probar ahora',
  learn_english: 'Aprende Inglés',
  strengthen_your_command_of_the_language_with_lessons_adapted_to_your_level_and_pace:
    'Fortalece tu dominio del idioma con lecciones adaptadas a tu nivel y ritmo.',
  interviewer: 'Entrevistador',
  practice_interviews_based_on_your_profile_and_gain_confidence_for_the_real_day:
    'Practica entrevistas basadas en tu perfil y gana confianza para el día real.',
  softskills: 'Softskills',
  discover_your_strengths_and_areas_of_improvement_in_soft_skills:
    'Descubre tus fortalezas y áreas de mejora en habilidades blandas.',
  assign_all: 'Asignar todos',
  talents_without_a_recruiter: 'Talentos sin reclutador',
  assign_talent: 'Asignar talento',
  select_talent: 'Seleccionar Talento',
  correctly_assigned_talents: 'Talentos asignados correctamente',
  an_error_has_occurred: 'Ha ocurrido un error',
  setting: 'Configuración',
  change_nickname: 'Cambiar apodo',
  email: 'Correo electrónico',
  change_password: 'Cambiar contraseña',
  password: 'Contraseña',
  continue: 'Continuar',
  current_password: 'Contraseña actual',
  enter_your_current_password: 'Ingresa tu contraseña actual',
  enter_your_password: 'Ingresa tu contraseña',
  new_password: 'Nueva contraseña',
  repeat_password: 'Repite contraseña',
  enter_your_new_password: 'Ingresa tu nueva contraseña',
  repeat_your_new_password: 'Repite tu nueva contraseña',
  desactivate_account: 'Desactivar cuenta',
  by_deactivating_your_account_your_profile_will_be_hidden_you_will_not_receive_any_more_offers_and_you_will_lose_contact_with_the_companies_and_your_manager:
    'Al desactivar tu cuenta, tu perfil quedará oculto, no recibirás más ofertas y perderás el contacto con las empresas y tu manager.',
  you_are_about_to_deactivate_your_account:
    'Estás a punto de desactivar tu cuenta',
  enter_your_password_to_continue: 'Escribe tu contraseña para continuar',
  your_account_has_been_deactivated: 'Tu cuenta ha sido desactivada',
  you_can_activate_your_account_again_whenever_you_want:
    'Podrás activar tu cuenta nuevamente cuando desees.',
  leaving_hutrit: 'Salir de Hutrit',
  reactivate_account: 'Reactivar cuenta',
  reactivate_your_account_to_make_your_profile_public_receive_new_offers_and_have_contact_with_your_manager_again:
    'Reactiva tu cuenta para hacer público tu perfil, recibir nuevas ofertas y tener contacto nuevamente con tu manager.',
  reactivating_your_account: 'Reactivando tu cuenta',
  your_account_was_successfully_reactivated:
    'Tu cuenta fue reactivada con éxito',
  enter_new_nickname: 'Ingresar nuevo apodo',
  nickname_updated_successfully: 'Apodo actualizado correctamente',
  nou_cancel: 'No, cancelar',
  yes_desactivate_account: 'Si, desactivar cuenta',
  talk_to_the_ceo: 'Habla con el CEO',
  list_of_candidates: 'Lista de candidatos',
  last_connection: 'Última conexión',
  download_pdf: 'Descargar PDF',
  loading_pdf: 'Cargando pdf',
  support: 'Soporte',
  support_tickets: 'Tickets de soporte',
  talent: 'Talento',
  prospects: 'Prospectos',
  mark_as_resolved: 'Marcar como resuelto',
  write_an_answer: 'Escribe una respuesta',
  recruiter_visits_to_your_profile: 'Visitas de reclutadores a tu perfil',
  number_of_recruiters_who_favorited_your_profile:
    'Cantidad de reclutadores que marcaron tu perfil como favorito',
  number_of_companies_that_have_made_you_an_offer:
    'Cantidad de empresas que te han hecho una oferta',
  talents_with_recruiter: 'Talentos con reclutador',
  menAll: 'Todos',
  apply_second_validation: 'Aplicar segunda validación',
  this_chat_was_marked_as_resolved: 'Este chat fue marcado como resuelto',
  very_badly: 'Muy mal',
  bad: 'Mal',
  regular: 'Regular',
  good: 'Bien',
  excellent: 'Excelente',
  rate_the_conversation: 'Califica la conversación',
  create_for: 'Creado por:',
  user_rating: 'Calificación del usuario',
  talk_to_your_talent_manager: 'Habla con tu Talent Manager',
  without_resolving: 'Sin resolver',
  resolved: 'Resuelto',
  complete_information_manually: 'Completar información manualmente',
  upload_your_linkedin_pdf: 'Sube tu PDF de LinkedIn',
  get_candidates: 'Obtener candidatos',
  name: 'Nombre',
  why_hutrit: 'Por qué Hutrit',
  request_for_offer: 'Solicitud de oferta',
  prospect: 'Prospecto',
  select_the_category: 'Selecciona la categoría',
  select_years_of_experience: 'Selecciona los años de experiencia',
  enter_your_name: 'Ingresa tu nombre',
  enter_your_email: 'Ingresa tu correo electrónico',
  metrics: 'Métricas',
  new_talents: 'nuevos talentos',
  search_by_name: 'Buscar por nombre',
  search: 'Buscar',
  clean_filter: 'Limpiar filtro',
  talent_manager_description: 'Descripción del talent manager',
  validate: 'Validar',
  change_idiom: 'Cambiar idioma',
  spanish: 'Español',
}

export const REQUIRED_FIELD = i18next?.t('this_field_is_required')

export const SHARE_SOCIAL_LINKS = {
  facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
  twitter: 'https://twitter.com/intent/tweet?url=',
  whatsapp: 'https://api.whatsapp.com/send?text=',
  pinterest: 'http://pinterest.com/pin/create/button/?url=',
  linkedin: 'https://www.linkedin.com/shareArticle?mini=true&url=',
}

export const RANGE_OF_TEAM_SIZE = [
  { id: 1, name: '0 - 10' },
  { id: 2, name: '10 - 25' },
  { id: 3, name: '25 - 50' },
  { id: 4, name: '50 - 100' },
  { id: 5, name: 'más de 100' },
]

// export const RANGE_OF_YEARS_OF_THE_COMPANY = [
//   { id: 1, name: 'menos de un año' },
//   { id: 2, name: '1 año' },
//   { id: 3, name: '2 años' },
//   { id: 4, name: '3 años' },
//   { id: 5, name: '4 años' },
//   { id: 6, name: '5 años' },
//   { id: 7, name: '6 años' },
//   { id: 8, name: '7 años' },
//   { id: 9, name: '8 años' },
//   { id: 10, name: '9 años' },
//   { id: 11, name: '10 años' },
//   { id: 12, name: 'más de 10 años' },
// ]

export const RANGE_OF_YEARS_OF_THE_COMPANY = [
  { name: '0-3 años', id: 1 },
  { name: '3-6 años', id: 2 },
  { name: '6-10 años', id: 3 },
  { name: 'Más de 10 años', id: 4 },
]
export const listOfCoins = {
  coins: [
    {
      id: 2,
      name: 'Euro',
      short_name: 'EUR',
      symbol: '€',
      active: true,
    },
    {
      id: 1,
      name: 'Dólar',
      short_name: 'USD',
      symbol: '$',
      active: true,
    },
  ],
}

export const LIMIT_OF_CHARACTERS = 1000

export const OUT_OF_THE_APP = typeUser === TYPE_OF_USER.out_off_the_app
export const IS_COMPANY = typeUser === TYPE_OF_USER.company
export const IS_TALENT = typeUser === TYPE_OF_USER.talent
export const IS_ADMIN = typeUser === TYPE_OF_USER.admin
export const IS_HIRING_ADVISOR = typeUser === TYPE_OF_USER.hiring_advisor
export const IS_RECRUITER = typeUser === TYPE_OF_USER.recruiter

export const TODAY = dayjs()

export const TAB_BAR_LIST = [
  {
    id: 0,
    name: i18next?.t('all'),
  },
  {
    id: 3,
    name: i18next?.t('accepted'),
  },
  {
    id: 1,
    name: i18next?.t('without_answer'),
  },
  {
    id: 2,
    name: i18next?.t('rejected'),
  },
]

export const LIMIT_OLD_NOTIFICATIONS_TO_SHOW = 3
export const LIMIT_NEW_NOTIFICATIONS_TO_SHOW = 3

export const LIST_OF_EMAIL_IMAGES = [
  {
    id: 1,
    img: 'https://res.cloudinary.com/dj6fxtuu5/image/upload/v1688436945/Illustration-2_yycchw.png',
    name: 'Cuenta activada',
  },
  {
    id: 2,
    img: 'https://res.cloudinary.com/dj6fxtuu5/image/upload/v1688437308/rafiki_pcpzky.png',
    name: 'Oferta',
  },
  {
    id: 3,
    img: 'https://res.cloudinary.com/dj6fxtuu5/image/upload/v1688436516/rafiki_vvxhki.png',
    name: 'Bienvenido',
  },
  {
    id: 4,
    img: ' https://res.cloudinary.com/dj6fxtuu5/image/upload/v1689379756/rafiki_hs8535.png',
    name: 'Recuperar contraseña',
  },
  {
    id: 5,
    img: 'https://res.cloudinary.com/dj6fxtuu5/image/upload/v1710291193/illustration_hrcjlq.png',
    name: 'Linkedin',
  },
  {
    id: 6,
    img: tm_company,
    name: 'Empresa TM',
  },
]

export const LIST_OF_EMAIL_VALIDATE_ACCOUNTS = [
  {
    id: 1,
    name: 'Todos',
    value: 0,
  },
  {
    id: 2,
    name: 'Perfiles completos',
    value: 1,
  },
  {
    id: 3,
    name: 'Perfiles incompletos',
    value: 2,
  },
]

export const LIST_OF_VALIDATE_ACCOUNTS = [
  {
    id: 1,
    name: 'Todos',
    value: 0,
  },
  {
    id: 2,
    name: 'Perfiles validados',
    value: 2,
  },
  {
    id: 3,
    name: 'Perfiles sin validar',
    value: 1,
  },
]

export const TYPE_OF_NOTIFICATIONS = {
  1: (
    <Circle className="bg-bg-Success">
      <BriefcaseOutlineIcon />
    </Circle>
  ),
  4: (
    <Circle className="bg-bg-Success">
      <PartyIcon />
    </Circle>
  ),
  5: (
    <Circle className="bg-bg-Success">
      <PartyIcon />
    </Circle>
  ),
}

export const LIMIT_OF_PROCESS_COLUMNS = 10

export const CHATGPT_ASSISTANT_LABELS = {
  default: 'default',
  translate: 'translate',
  interviewer: 'interviewer',
  softskills: 'softskills',
}

export const TYPE_OF_CHAT_USER = {
  company: i18next?.t('company'),
  recruiter: i18next?.t('hutrit_manager'),
  talent: i18next?.t('talent'),
}

export const SUPPORT_TAB_BAR_LABELS = [
  {
    id: 1,
    name: i18next?.t('all'),
    value: '',
  },
  {
    id: 2,
    name: i18next?.t('talent'),
    value: '',
  },
  {
    id: 3,
    name: i18next?.t('company'),
    value: '',
  },
  {
    id: 4,
    name: 'Hiring Advisors',
    value: '',
  },
  {
    id: 5,
    name: 'Talent Manager',
    value: '',
  },
  {
    id: 6,
    name: i18next?.t('prospects'),
    value: '',
  },
]

export const LIST_OF_EMOTICONS = [
  {
    icon_url: very_bad_face_image,
    label: i18next?.t('very_badly'),
    id: 1,
  },
  {
    icon_url: bad_face_image,
    label: i18next?.t('bad'),
    id: 2,
  },
  {
    icon_url: regular_face_image,
    label: i18next?.t('regular'),
    id: 3,
  },
  {
    icon_url: good_face_image,
    label: i18next?.t('good'),
    id: 4,
  },
  {
    icon_url: excellent_face_image,
    label: i18next?.t('excellent'),
    id: 5,
  },
]

export const TYPE_PROFILE = {
  recruiter: 'recruiter',
}

export const SUPPORT_STATE = {
  open: 1,
  close: 2,
}

export const QUICK_OFFER_STATUS = {
  resolved: 'resolved',
  in_progress: 'in_progress',
  cancelled: 'cancelled',
}

export const LIST_OF_QUICK_OFFER_STATUS = [
  {
    id: 1,
    url: quick_offer_check,
    label: i18next?.t('resolved'),
    value: 2,
  },
  {
    id: 2,
    url: quick_offer_process,
    label: 'En proceso',
    value: 1,
  },
  {
    id: 2,
    url: quick_offer_cancelled,
    label: 'Cancelado',
    value: 3,
  },
]

export const LIST_OF_IDIOMS = {
  spanish: 'es',
  english: 'en',
}

export const TRANSLATE_LABELS = {
  es: 'ES',
  en: 'EN',
}

export const TYPE_OF_PERCENTAGE = {
  positive: 1,
  negative: -1,
}

export const CURRENT_LABEL_CHART = {
  today: i18next?.t('today'),
  weekly: 'Semana actual',
  monthly: 'Mes actual',
}

export const PREVIUS_LABEL_CHART = {
  today: 'Ayer',
  weekly: 'Semana anterior',
  monthly: 'Mes anterior',
}

export const SKELETON_THEME = {
  baseColor: '#ebebeb',
  highlightColor: '#f5f5f5',
}

export const TM_FILTERS_OPTIONS = {
  without_approving: 1,
  approved: 2,
}

export const MOCKUP_LIST_TALENTS = [
  {
    name: 'John Doe',
    nickname: '@JD',
    position: 'Software Engineer',
  },
  {
    name: 'Jane Smith',
    nickname: '@JS',
    position: 'Data Analyst',
  },
  {
    name: 'Michael Johnson',
    nickname: '@MJ',
    position: 'UX Designer',
  },
]
export const SORT_VALUES = {
  desc: 'desc',
  asc: 'asc',
}

export const LIST_OF_FREQUENT_QUESTIONS = [
  {
    id: 1,
    question: i18next.t('question_one'),
    answer: i18next.t('answer_one'),
  },
  {
    id: 2,
    question: i18next.t('question_two'),
    answer: i18next.t('answer_two'),
  },
  {
    id: 3,
    question: i18next.t('question_three'),
    answer: i18next.t('answer_three'),
  },
  {
    id: 4,
    question: i18next.t('question_four'),
    answer: i18next.t('answer_four'),
  },
  {
    id: 5,
    question: i18next.t('question_five'),
    answer: i18next.t('answer_five'),
  },
  {
    id: 6,
    question: i18next.t('question_six'),
    answer: i18next.t('answer_six'),
  },
  {
    id: 7,
    question: i18next.t('question_seven'),
    answer: i18next.t('answer_seven'),
  },
  {
    id: 8,
    question: i18next.t('question_eight'),
    answer: i18next.t('answer_eight'),
  },
  {
    id: 9,
    question: i18next.t('question_nine'),
    answer: i18next.t('answer_nine'),
  },
  {
    id: 10,
    question: i18next.t('question_ten'),
    answer: i18next.t('answer_ten'),
  },
  {
    id: 11,
    question: i18next.t('question_eleven'),
    answer: i18next.t('answer_eleven'),
  },
  {
    id: 12,
    question: i18next.t('question_twelve'),
    answer: i18next.t('answer_twelve'),
  },
  {
    id: 13,
    question: i18next.t('question_thirteen'),
    answer: i18next.t('answer_thirteen'),
  },
  {
    id: 14,
    question: i18next.t('question_fourteen'),
    answer: i18next.t('answer_fourteen'),
  },
  {
    id: 15,
    question: i18next.t('question_fifteen'),
    answer: i18next.t('answer_fifteen'),
  },
  {
    id: 16,
    question: i18next.t('question_sixteen'),
    answer: i18next.t('answer_sixteen'),
  },
  {
    id: 17,
    question: i18next.t('question_seventeen'),
    answer: i18next.t('answer_seventeen'),
  },
  {
    id: 18,
    question: i18next.t('question_eighteen'),
    answer: i18next.t('answer_eighteen'),
  },
]

export const TRAFFIC_LIGHT_LEVELS = {
  red: 'red',
  yellow: 'yellow',
  green: 'green',
}

export const TRAFFIC_LIGHT_LEVELS_VALUE = {
  red: 1,
  yellow: 2,
  green: 3,
}

export const TRAFFIC_LIGHT_RULES_QUANTITY_STEP_FOUR = {
  zero_to_three: {
    low: {
      quantity: 3,
    },
    medium: {
      min: 3,
      max: 4,
    },
    hard: {
      quantity: 5,
    },
  },
  three_or_more: {
    low: {
      quantity: 3,
    },
    medium: {
      min: 3,
      max: 9,
    },
    hard: {
      quantity: 10,
    },
  },
}

export const TYPE_OF_USER_NAME = {
  talent: 'talent',
  recruiter: 'recruiter',
  company: 'company',
}

export const LOGIN_TYPE_OF_USER = {
  talent: 1,
  company: 2,
  tm: 3,
}

export const STEPS = {
  initial: 0,
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  six: 6,
  endStep: 7,
}

export const LIST_OF_MONTHS = [
  {
    id: 0,
    name: i18next?.t('january'),
  },
  {
    id: 1,
    name: i18next?.t('february'),
  },
  {
    id: 2,
    name: i18next?.t('march'),
  },
  {
    id: 3,
    name: i18next?.t('april'),
  },
  {
    id: 4,
    name: i18next?.t('may'),
  },
  {
    id: 5,
    name: i18next?.t('june'),
  },
  {
    id: 6,
    name: i18next?.t('july'),
  },
  {
    id: 7,
    name: i18next?.t('august'),
  },
  {
    id: 8,
    name: i18next?.t('september'),
  },
  {
    id: 9,
    name: i18next?.t('october'),
  },
  {
    id: 10,
    name: i18next?.t('november'),
  },
  {
    id: 11,
    name: i18next?.t('december'),
  },
]

const currentYear = new Date().getFullYear()
const yearsArray = []

for (let year = 1924; year <= currentYear; year++) {
  yearsArray.push({ id: year, name: year.toString() })
}

export const LIST_OF_YEARS = [...yearsArray.reverse()]

export const TYPE_OF_AUTH = {
  google: 'google',
  linkedin: 'linkedin',
}
